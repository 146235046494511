import React, { Component, MouseEvent, ReactElement, SyntheticEvent } from 'react';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import styled from 'styled-components';
import createMentionPlugin, { defaultSuggestionsFilter, MentionPluginTheme } from '@draft-js-plugins/mention';
import createEmojiPlugin, { EmojiPlugin } from '@draft-js-plugins/emoji';
import createMarkdownShortcutsPlugin from 'draft-js-markdown-shortcuts-plugin';
import Icon, { SmileOutlined } from '@ant-design/icons';
import { UserAvatars } from '@powtoon/design-system-components';

import GuestAvatarPNG from '~/shared/images/GuestAvatar.png';
// @TODO: we should replace default CSS with custom styling and remove it outside this file
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import './editorStyles.css';
import { MentionData } from '~/shared/recoil';

const EditorWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.textLight};
  padding: 8px 12px;
  width: 100%;
  height: auto;
  font-size: 14px;
  min-height: 40px;
  border-radius: 5px;
  :hover,
  :focus,
  :focus-within {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  a {
    color: inherit !important;
    font-weight: bold !important;
    background: transparent;
  }
`;

const EmptyEmojiSelectButtonWrapper = styled.div`
  margin: 0px 5px 5px 5px;
  font-size: 18px;
  padding-top: 4px;
  color: gray;
  cursor: not-allowed;
`;

const EmojiSelectButtonWrapper = styled.div``;

const MentionButton = styled.div`
  cursor: pointer;
  align-self: center;
  font-size: 19px;
  color: ${({ theme }) => theme.colors.divider};
`;

// const TimeBlock = styled.div`
//   margin-right: auto;
//   align-self: center;
//   font-size: 12px;
//   color: ${({ theme }) => theme.colors.primary};
// `;

const EditorBottomWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export interface EntryComponentProps {
  className?: string;
  onMouseDown(event: MouseEvent): void;
  onMouseUp(event: MouseEvent): void;
  onMouseEnter(event: MouseEvent): void;
  role: string;
  id: string;
  'aria-selected'?: boolean | 'false' | 'true';
  theme?: MentionPluginTheme;
  mention: MentionData;
  isFocused: boolean;
  searchValue?: string;
}

function Entry(props: EntryComponentProps): ReactElement {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...parentProps
  } = props;

  return (
    <div {...parentProps}>
      <UserAvatars
        avatarsSize="medium"
        maxVisibleItem={1}
        users={[{ id: mention.id!, name: mention.name, image: mention.avatar }]}
      />
      <span className={theme?.mentionSuggestionsEntryText}>{mention.name}</span>
    </div>
  );
}

interface RichTextEditorProps {
  onChange: (val: EditorState, n: MentionData[]) => any;
  onClick?: () => void;
  mentionSuggestions: MentionData[];
  isReply?: boolean;
  innerText?: string;
  defaultState?: EditorState;
  readOnly?: boolean;
  keyBindingFunc?: (e: SyntheticEvent) => void;
  handleSend?: () => void;
}

interface RichTextEditorState {
  editorState: EditorState;
  suggestions: any[];
  newMentions: MentionData[];
  open: boolean;
  plugins: any[];
}

let mentionPlugin: any;
let markdownPlugin: any;
let emojiPlugin: EmojiPlugin | undefined;

export default class RichTextEditor extends Component<RichTextEditorProps, RichTextEditorState> {
  editor: any;
  editorFocusTimeout?: number;

  constructor(props: RichTextEditorProps) {
    super(props);
    const { defaultState, mentionSuggestions } = props;
    this.editorFocusTimeout = undefined;
    if (!mentionPlugin) {
      mentionPlugin = createMentionPlugin({
        mentionPrefix: '@',
        entityMutability: 'MUTABLE',
        popperOptions: { placement: 'top-start' },
        mentionComponent(mentionProps) {
          return (
            <a className={mentionProps.className} href="#mention-${mentionProps.mention.id}">
              {mentionProps.children}
            </a>
          );
        },
      });
    }
    if (!markdownPlugin) {
      markdownPlugin = createMarkdownShortcutsPlugin();
    }

    this.state = {
      editorState: defaultState || EditorState.createEmpty(),
      suggestions: mentionSuggestions.map(s => (s.avatar ? s : { ...s, avatar: GuestAvatarPNG })),
      newMentions: [],
      open: false,
      plugins: [markdownPlugin, mentionPlugin, ...(emojiPlugin ? [emojiPlugin] : [])],
    };

    // binds
    this.onOpenChange = this.onOpenChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._onClick = this._onClick.bind(this);
    this.handleMentionTrigger = this.handleMentionTrigger.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.focus = this.focus.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.focus();
    }, 200);
  }

  componentDidUpdate() {
    if (!this.props.readOnly && !emojiPlugin) {
      emojiPlugin = createEmojiPlugin({ selectButtonContent: <SmileOutlined /> });
      this.setState({
        plugins: [...this.state.plugins, emojiPlugin],
      });
    }
  }
  componentWillUnmount() {
    window.clearTimeout(this.editorFocusTimeout);
  }

  onOpenChange = (_open: boolean) => {
    this.setState({ open: _open });
  };

  onSearchChange = ({ value }: { value: string }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(
        value,
        this.props.mentionSuggestions.map(s => (s.avatar ? s : { ...s, avatar: GuestAvatarPNG }))
      ),
    });
  };

  // listen to state change on the editor and update the upper components
  handleChange = (e: EditorState) => {
    this.setState({ editorState: e });
    if (!e.getSelection().getHasFocus()) {
      e = EditorState.moveFocusToEnd(e);
    }
    this.props.onChange(e, this.state.newMentions);
  };

  _onClick = (evt: MouseEvent) => {
    // avoid focus again after mention, since mention trigger will handle a complex state case
    if (evt.currentTarget.innerHTML !== '@') {
      setTimeout(() => {
        this.focus();
      }, 300);
    }
    this.props.onClick ? this.props.onClick() : true;
  };

  // handle manual mention button trigger
  handleMentionTrigger = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const newContentState = Modifier.insertText(
      currentContent,
      this.state.editorState.getSelection(),
      // if editor has text, add space separator before the mention sign
      currentContent.hasText() ? ' @' : '@'
    );
    const newEditorState = EditorState.push(this.state.editorState, newContentState, 'insert-fragment');
    this.setState({
      editorState: newEditorState,
    });
    setTimeout(() => {
      this.focus();
    }, 200);
  };
  //reply editor now submit replies with enter
  //this method covers all return key functionalities needed
  //prevents the newline insertion after reply submit
  handleReturn(e: any, editorState: EditorState) {
    if (e.key === 'Enter' && !e.shiftKey && (!this.state.open || !this.state.suggestions.length)) {
      if (this.props.handleSend) {
        //Submit func on enter
        this.props.handleSend();
        return 'handled';
      }
    }
    if (e.shiftKey && e.key === 'Enter') {
      const newState = RichUtils.insertSoftNewline(editorState);
      this.handleChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }
  // enable key commands to make comments
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleKeyCommand = (command: string, editorState: EditorState, eventTimeStamp: number) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.handleChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  // auto focus the keyboard cursor to editor
  focus = () => {
    this.props.onClick ? this.props.onClick() : true;
    this.editor.focus();
  };

  mailIcon = () => (
    <svg width="1em" height="1em" viewBox="0 0 64 64" fill="gray">
      <path d="M32,60A28,28,0,0,1,32,4c15.888,0,25,8.748,25,24v1a18.064,18.064,0,0,1-2.313,9.706A9.9,9.9,0,0,1,46,43.754c-3.987,0-8.643-2.3-8.643-8.771l0-.1.6-11.944q-.74-.146-1.532-.263a29.192,29.192,0,0,0-4.1-.337,8.536,8.536,0,0,0-6.725,2.7A10.591,10.591,0,0,0,23,32.594a9.459,9.459,0,0,0,1.735,6.122,5.191,5.191,0,0,0,4.338,1.8,5.952,5.952,0,0,0,3.373-.9,2,2,0,1,1,2.2,3.342,9.818,9.818,0,0,1-5.572,1.554,9.142,9.142,0,0,1-7.425-3.26C19.891,39.127,19,36.212,19,32.594a14.394,14.394,0,0,1,3.67-10.271,12.6,12.6,0,0,1,9.66-3.984,33.24,33.24,0,0,1,4.677.379,32.794,32.794,0,0,1,3.547.711l1.57.414-.767,15.188c.022,4.107,2.915,4.723,4.643,4.723a5.988,5.988,0,0,0,5.152-2.921A14.063,14.063,0,0,0,53,29V28C53,15.1,45.542,8,32,8A24,24,0,1,0,48.615,49.318a2,2,0,1,1,2.77,2.887A27.866,27.866,0,0,1,32,60Z" />
    </svg>
  );

  render() {
    const { readOnly } = this.props;
    const { MentionSuggestions } = mentionPlugin;
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin || {};
    const { newMentions, open, suggestions, editorState, plugins } = this.state;

    const EmojiButtonWrapper =
      EmojiSuggestions && EmojiSelect ? EmojiSelectButtonWrapper : EmptyEmojiSelectButtonWrapper;

    return (
      <EditorWrapper
        style={{ border: readOnly ? '0px' : '' }}
        className="powtoon-emojies-mention-rte powtoon-main-editor Animator"
      >
        <div style={{ marginBottom: '10px' }} onClick={this.focus} data-cy="rich-text-editor">
          <Editor
            key={plugins.length}
            readOnly={readOnly}
            handleReturn={this.handleReturn}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.handleChange}
            plugins={plugins}
            ref={element => {
              this.editor = element;
            }}
          />
        </div>
        <MentionSuggestions
          open={open}
          onOpenChange={this.onOpenChange}
          suggestions={suggestions}
          onSearchChange={this.onSearchChange}
          onAddMention={(user: MentionData) => {
            this.setState({ newMentions: [...newMentions, user] });
          }}
          entryComponent={Entry}
        />
        {EmojiSuggestions && <EmojiSuggestions />}
        {!readOnly && (
          <EditorBottomWrapper className="pt-rte-bottom-wrapper" onClick={this._onClick}>
            <EmojiButtonWrapper className="pt-emoji-select-button-wrapper">
              {EmojiSelect ? <EmojiSelect closeOnEmojiSelect /> : <SmileOutlined />}
            </EmojiButtonWrapper>
            <MentionButton className="pt-mention-button-wrapper" onClick={this.handleMentionTrigger}>
              <Icon component={this.mailIcon} />
            </MentionButton>
            {/* <TimeBlock>{this.props.innerText}</TimeBlock> */}
          </EditorBottomWrapper>
        )}
      </EditorWrapper>
    );
  }
}
