import React, { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { groupBy } from 'lodash';
import {
  NotificationDescriptor,
  PowtoonStudio,
  PowtoonUser,
  StudioModelEvent,
  StudioUiEvent,
} from '@powtoon/plugin-lib';

import { Comments } from '~/comments';
import { commentsState, Comment as CommentType, MentionData } from '~/shared/recoil';
import { FlexColumn } from '~/shared/components/flexes';
import useHostStateData from '~/shared/hooks/useHostStateData';
// import useCommentActions from '~/shared/hooks/useCommentActions';
import UpdateMillisecParams from '~/shared/types/UpdateMillisecParams';
import CommentsFilterType from '~/shared/types/CommentsFilterType';
import useComments from '~/shared/hooks/useComments';
import toCssClassNameString from '~/shared/utils/deviceDetector';
import { useStudio } from '~/shared/hooks/useStudio';
import '@powtoon/design-system-components/dist/global.css';
import { LoggerContext } from '~/shared/contexts/LoggerContext';

const Root = styled(FlexColumn)`
  box-shadow: 0 1px 5px 0 ${({ theme }) => theme.colors.backgroundHover};
  background: white;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  position: relative;
  padding-bottom: 188px;
`;

export default function Shell({ studio }: { studio: PowtoonStudio }) {
  const logger = useContext(LoggerContext);
  const [hostDataState, hostDataActions] = useHostStateData();
  const [clientComments, setClientComments] = useRecoilState(commentsState);
  const [currentFilter, setCurrentFilter] = useState<CommentsFilterType>(CommentsFilterType.ALL);
  const [millisecUpdateTrigger] = useState<{
    data: UpdateMillisecParams;
    rollback?: boolean;
  }>({
    data: { increment: 0, start: 10000 },
  });

  const lastNotifiedBadgeValue = useRef(new Map<string, number>());

  const { refreshPowtoonAccessToken, refetchPowtoonUsers } = useStudio({ studio });

  const {
    filteredComments,
    filterBy,
    optimisticUpdateModify,
    optimisticUpdateInsert,
    optimisticUpdateRemove,
    loading,
  } = useComments({ refreshPowtoonAccessToken, refetchPowtoonUsers });

  /**
   * Set Studio Badge event trigger?
   */
  useEffect(() => {
    const openComments = clientComments.filter(comment => !comment.resolvedAt);
    const grouped = groupBy(openComments, 'slideId');
    hostDataState.slides.forEach(({ id }) => {
      const commentsAmount = grouped[id]?.length;
      if (lastNotifiedBadgeValue.current.get(id) === commentsAmount) {
        return;
      }
      studio.setBadge({
        target: { type: 'slideThumb', id },
        badge: { type: 'text', data: `${commentsAmount || ''}` },
      });
      lastNotifiedBadgeValue.current.set(id, commentsAmount);
    });
  }, [clientComments, hostDataState.slides, studio]);

  /**
   * Handle MilliSec UI Slider
   */
  useEffect(() => {
    const { data, rollback } = millisecUpdateTrigger;
    optimisticUpdateModify(data, rollback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [millisecUpdateTrigger]);

  // const { mutateCommentsTime } = useCommentActions({
  //   powtoonId: hostDataState.powtoonId,
  //   selectedSlideId: hostDataState.selectedSlideId,
  // });

  /**
   * Handle the filters type
   */
  useEffect(() => {
    filterBy(currentFilter, hostDataState.currentUser?.userId);
    if (hostDataState.powtoonId)
      logger.log({
        logLevel: 'info',
        powtoonId: hostDataState.powtoonId,
        userId: hostDataState.currentUser?.userId,
        message: `${currentFilter} comments list updated`,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter, clientComments, hostDataState.slides]);

  /**
   * Switch plugin to default empty statue when there is no comments.
   */
  useEffect(() => {
    !filteredComments.length && !clientComments.length ? setCurrentFilter(CommentsFilterType.ALL) : '';
  }, [clientComments.length, filteredComments.length]);

  /**
   * Setup default Plugin events Handlers
   * events will receive default parameters
   * required to run the Plugin
   */
  const modelEventHandler = useCallback(
    async (event: StudioModelEvent) => {
      if (event.name === 'ready') {
        refreshPowtoonAccessToken();
        // @TODO store the owner ID
        logger.log({ logLevel: 'info', powtoonId: event.powtoonId, message: 'Powtoon Comment Plugin Initiated' });

        hostDataActions.setPowtoonId(event.powtoonId);

        const playHeadPositionData = await studio.getDataHandler('getPlayheadPosition', {});

        const slides = await studio.getDataHandler('getSlides', {});
        hostDataActions.setSlides(slides.slides);

        if (playHeadPositionData.powtoonId) {
          const { powtoonId, slideId, millisec } = playHeadPositionData;
          hostDataActions.setSelectedSlideId(slideId);
          hostDataActions.setTimelineStart(millisec);
          hostDataActions.setPowtoonId(powtoonId);
        }

        const powtoonUsers: { [key: string]: PowtoonUser } = await studio.getDataHandler('getPowtoonUsers', {});
        const mentions: MentionData[] = Object.keys(powtoonUsers).map(id => {
          const user: PowtoonUser = powtoonUsers[id];
          const mentionsListItemData: MentionData = { id, name: `${user.firstName} ${user.lastName}` };
          if (!user.thumbUrl)
            console.error(
              `Studio.getPowtoonUsers() returned invalid thumbURL for user ${id} on powtoon ${event.powtoonId}`
            );
          return user.thumbUrl ? { ...mentionsListItemData, avatar: user.thumbUrl } : mentionsListItemData;
        });
        hostDataActions.setMentionSuggestion(mentions || []);

        const pluginInitData = await studio.getDataHandler('getPluginInitData', {});

        if (pluginInitData) {
          const { ownerId } = pluginInitData;
          mentions.filter(u => u.id == ownerId).map(user => hostDataActions.setPowtoonOwner(user));
        }
      }

      if (event.name === 'slides') {
        hostDataActions.setSlides(event.slides);
      }

      // TODO: outdated functionality for now

      // if (event.name === 'slideTime') {
      //   const data = {
      //     increment: event.durationMillisec,
      //     start: event.startMillisec - event.durationMillisec,
      //   };
      //   setMillisecUpdateTrigger({ data });
      //   const resp = await mutateCommentsTime({ variables: data });
      //   if (!resp?.data?.updateCommentsTime) {
      //     setMillisecUpdateTrigger({ data, rollback: true });
      //   }
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hostDataActions.setPowtoonId, hostDataActions.setSlides, setClientComments]
  );

  const uiEventHandler = useCallback(
    (event: StudioUiEvent) => {
      if (event.name === 'selection') {
        hostDataActions.setSelectedSlideId(event.target.id);
      }
      if (event.name === 'timeline') {
        hostDataActions.setTimelineStart(event.millisec);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hostDataActions.setSelectedSlideId, hostDataActions.setTimelineStart]
  );

  useEffect(
    () => {
      studio.registerModelEventHandler(modelEventHandler);

      studio.registerUiEventHandler(uiEventHandler);

      studio.ready();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onCommentClick = (comment: CommentType) => {
    studio.pause();
    studio.navigateToTarget({ target: { type: 'slideThumb', id: comment.slideId } });
  };

  const sendNotification = (notification: NotificationDescriptor) => {
    logger.log({
      logLevel: 'info',
      powtoonId: hostDataState.powtoonId,
      userId: hostDataState.currentUser?.userId,
      message: `${notification.notificationType} notification sent by ${notification.commenterId} to ${JSON.stringify(
        notification.recipients
      )}`,
    });
    studio.notifyUsers(notification);
  };

  const onInviteBtnClick = () => {
    logger.log({
      logLevel: 'info',
      powtoonId: hostDataState.powtoonId,
      userId: hostDataState.currentUser?.userId,
      message: `${hostDataState.currentUser?.userId} requested the invite dialog`,
    });
    studio.openShareDialog();
  };

  const onMouseLeave = () => {
    hostDataActions.setFocusStatus(false);
  };

  const onMouseEnter = () => {
    hostDataActions.setFocusStatus(true);
  };

  return (
    <Root onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} className={`${toCssClassNameString()} Animator`}>
      <span hidden data-cy="plugin-slide-id">
        {hostDataState.selectedSlideId}
      </span>
      <span hidden data-cy="plugin-time">
        {hostDataState.timelineStart}
      </span>
      <Comments
        {...{
          comments: filteredComments,
          disableFilters: !clientComments.length || !hostDataState.onlineStatus,
          loading,
          optimisticUpdateInsert,
          optimisticUpdateRemove,
          setClientComments,
          currentFilter,
          setCurrentFilter,
          sendNotification,
          refreshPowtoonAccessToken,
          onInviteBtnClick,
          onCommentClick,
        }}
      />
    </Root>
  );
}
